@layer modules, ui, base;
@layer base {
  .DebugAlgoliaRequests_main__DCPWA > div {
  display: flex;
  align-items: center;
  gap: var(--sk-space-4);
}

.DebugAlgoliaRequests_main__DCPWA h4 {
  margin: var(--sk-space-4) 0;
}

.DebugAlgoliaRequests_main__DCPWA button {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

.DebugAlgoliaRequests_main__DCPWA button:hover {
  text-decoration: underline;
}

}
@layer base {
  .DebugInfoDisplay_main__sSG9K {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.DebugInfoDisplay_debugLine__4jp6o {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--sk-space-4);
}

.DebugInfoDisplay_debugLine__4jp6o > div {
  display: flex;
  gap: var(--sk-space-8);
}

.DebugInfoDisplay_debugLine__4jp6o span {
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-weight: bold;
}

.DebugInfoDisplay_debugLine__4jp6o code {
  width: 90%;
  word-break: break-all;
}

.DebugInfoDisplay_debugLine__4jp6o a {
  color: inherit;
}

}
@layer base {
  .DebugMenu_main__gmbO_ * {
  box-sizing: border-box;
}

.DebugMenu_main__gmbO_ {
  display: flex;
  position: fixed;
  z-index: var(--z-index-dev-tools);
  top: 2px;
  left: 2px;
  transition: 0.2s ease-in-out;
  font-size: 16px;
}

.DebugMenu_main__collapsed__sQHBh {
  transform: translateX(calc(-100% + 16px));
}

.DebugMenu_main__collapsed__sQHBh .DebugMenu_buttonStatus__bNpmp::after {
  transform: rotateY(180deg);
}

.DebugMenu_main__gmbO_ h3 {
  margin-top: 2px;
}

.DebugMenu_content__8S_Ox {
  width: 332px;
  padding: 10px;
  border-radius: 4px;
  background-color: var(--sk-color-grey-800);
  color: var(--sk-color-white);
  font-size: 16px;
}

.DebugMenu_button__vsizd {
  width: 38px;
  height: 38px;
  border: 0;
  border-radius: 4px;
  background-color: var(--sk-color-grey-800);
  color: var(--sk-color-white);
  line-height: normal;
  cursor: pointer;
}

.DebugMenu_buttonStatus__bNpmp {
  position: relative;
  width: 332px;
  font-size: 16px;
}

.DebugMenu_buttonStatus__bNpmp::after {
  content: "<";
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 4px;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
}

.DebugMenu_buttonEdit__gfPnm {
  margin-left: 2px;
}

.DebugMenu_buttonEdit__gfPnm::after {
  content: "✎";
  display: inline-block;
  transform: rotateY(180deg);
  font-size: 24px;
}

}
@layer ui {
  .sk-Callout_main__2MPhi {
  display: flex;
  justify-content: space-between;
  padding: var(--sk-space-8);
  border-radius: var(--sk-overlay-toast-radius);
  gap: var(--sk-space-16);
}

.sk-Callout_main__2MPhi.sk-Callout_main--info__B4HlS {
  background-color: var(--sk-color-purple-100);
}

.sk-Callout_main__2MPhi.sk-Callout_main--success___UTJz {
  background-color: var(--sk-color-success-100);
}

.sk-Callout_main__2MPhi.sk-Callout_main--warning__PNKsY {
  background-color: var(--sk-color-warning-100);
}

.sk-Callout_main__2MPhi.sk-Callout_main--error__jmWNf {
  background-color: var(--sk-color-error-100);
}

.sk-Callout_main__2MPhi .sk-Callout_title__p9acV {
  font-family: var(--sk-typography-body-1-semibold-font-family);
  font-size: var(--sk-typography-body-1-semibold-font-size);
  font-weight: var(--sk-typography-body-1-semibold-font-weight);
  line-height: var(--sk-typography-body-1-semibold-line-height);
}

.sk-Callout_main__2MPhi .sk-Callout_content__fNCGc {
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.sk-Callout_main__2MPhi .sk-Callout_icon__kSnm5 {
  flex-shrink: 0;
  padding: var(--sk-space-2);
}

.sk-Callout_main__2MPhi:has(.sk-Callout_title__p9acV) .sk-Callout_icon__kSnm5 {
  padding-top: var(--sk-space-4);
}

.sk-Callout_main__2MPhi .sk-Callout_closeButton__KWZWj {
  padding: var(--sk-space-4);
}

.sk-Callout_main__2MPhi .sk-Callout_bodyContainer__RkLZL {
  display: flex;
}

.sk-Callout_main__2MPhi .sk-Callout_contentContainer__Lh_wY {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: var(--sk-space-2) var(--sk-space-4);
  gap: var(--sk-space-4);
}

.sk-Callout_main--light__D5Wx6 {
  background-color: var(--sk-overlay-toast-color-background-light);
  color: var(--sk-overlay-toast-color-light);
}

.sk-Callout_main--light__D5Wx6.sk-Callout_main--info__B4HlS .sk-Callout_iconContainer__w7xGX {
  background-color: var(--sk-overlay-toast-icon-container-color-background-info-light);
}

.sk-Callout_main--light__D5Wx6.sk-Callout_main--success___UTJz .sk-Callout_iconContainer__w7xGX {
  background-color: var(--sk-overlay-toast-icon-container-color-background-success-light);
}

.sk-Callout_main--light__D5Wx6.sk-Callout_main--warning__PNKsY .sk-Callout_iconContainer__w7xGX {
  background-color: var(--sk-overlay-toast-icon-container-color-background-warning-light);
}

.sk-Callout_main--light__D5Wx6.sk-Callout_main--error__jmWNf .sk-Callout_iconContainer__w7xGX {
  background-color: var(--sk-overlay-toast-icon-container-color-background-error-light);
}

.sk-Callout_main--light__D5Wx6.sk-Callout_main--info__B4HlS .sk-Callout_icon__kSnm5 {
  color: var(--sk-overlay-toast-icon-container-color-info-light);
}

.sk-Callout_main--light__D5Wx6.sk-Callout_main--success___UTJz .sk-Callout_icon__kSnm5 {
  color: var(--sk-overlay-toast-icon-container-color-success-light);
}

.sk-Callout_main--light__D5Wx6.sk-Callout_main--warning__PNKsY .sk-Callout_icon__kSnm5 {
  color: var(--sk-overlay-toast-icon-container-color-warning-light);
}

.sk-Callout_main--light__D5Wx6.sk-Callout_main--error__jmWNf .sk-Callout_icon__kSnm5 {
  color: var(--sk-overlay-toast-icon-container-color-error-light);
}

.sk-Callout_main--dark__1ogOx {
  background-color: var(--sk-overlay-toast-color-background-dark);
  color: var(--sk-overlay-toast-color-dark);
}

.sk-Callout_main--dark__1ogOx.sk-Callout_main--info__B4HlS .sk-Callout_iconContainer__w7xGX {
  background-color: var(--sk-overlay-toast-icon-container-color-background-info-dark);
}

.sk-Callout_main--dark__1ogOx.sk-Callout_main--success___UTJz .sk-Callout_iconContainer__w7xGX {
  background-color: var(--sk-overlay-toast-icon-container-color-background-success-dark);
}

.sk-Callout_main--dark__1ogOx.sk-Callout_main--warning__PNKsY .sk-Callout_iconContainer__w7xGX {
  background-color: var(--sk-overlay-toast-icon-container-color-background-warning-dark);
}

.sk-Callout_main--dark__1ogOx.sk-Callout_main--error__jmWNf .sk-Callout_iconContainer__w7xGX {
  background-color: var(--sk-overlay-toast-icon-container-color-background-error-dark);
}

.sk-Callout_main--dark__1ogOx.sk-Callout_main--info__B4HlS .sk-Callout_icon__kSnm5 {
  color: var(--sk-overlay-toast-icon-container-color-info-dark);
}

.sk-Callout_main--dark__1ogOx.sk-Callout_main--success___UTJz .sk-Callout_icon__kSnm5 {
  color: var(--sk-overlay-toast-icon-container-color-success-dark);
}

.sk-Callout_main--dark__1ogOx.sk-Callout_main--warning__PNKsY .sk-Callout_icon__kSnm5 {
  color: var(--sk-overlay-toast-icon-container-color-warning-dark);
}

.sk-Callout_main--dark__1ogOx.sk-Callout_main--error__jmWNf .sk-Callout_icon__kSnm5 {
  color: var(--sk-overlay-toast-icon-container-color-error-dark);
}

}
@layer ui {
  /* @define @UploadBlock */

.UploadBlock_jds-UploadBlock__g_FOq {
  display: flex;
  justify-content: center;
}

.UploadBlock_jds-UploadBlock__input__Sr2p2.UploadBlock_jds-UploadBlock__input__Sr2p2 { /* double class to override some bootstrap-light styles */
  display: none;
}

}
@layer ui {
  /* @define FileBlock */

.FileBlock_jds-FileBlock__BCAkG {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacer-200);
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-200);
}

.FileBlock_jds-FileBlock__infos__K7wx6 {
  width: 100%;
}

.FileBlock_jds-FileBlock__actions__npyi_ {
  flex: 1 0 auto;
  margin-left: var(--spacer-200);
}

}
@layer ui {
  /* @define UploadFiles */

.UploadFiles_jds-UploadFiles__1Maj7 {
  padding: var(--spacer-300) var(--spacer-200);
  border-radius: var(--radius-200);
  background: var(--color-grey--025);
}

.UploadFiles_jds-UploadFiles--hidden__wAh24 {
  display: none;
}

}
@layer base {
  .NewLoginModal_main__PPiIr {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.NewLoginModal_head__9h832 {
  margin-bottom: var(--sk-space-48);
}

.NewLoginModal_autocomplete___GBWW {
  display: block;
  position: relative;
  margin-bottom: var(--sk-space-16);
}

.NewLoginModal_label__ZwnhZ {
  margin-bottom: var(--sk-space-8);
}

.NewLoginModal_input__X2Dpi {
  box-sizing: border-box;
  width: 100%;
  min-height: 48px;
  margin-bottom: var(--sk-space-4);
  padding: 0 var(--sk-space-16);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  font-family: inherit;
  font-size: var(--sk-font-size-1);
  font-weight: var(--sk-font-weights-poppins-2);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-transform: capitalize;
}

.NewLoginModal_input__X2Dpi::-moz-placeholder {
  color: var(--sk-color-grey-600);
  text-transform: none;
}

.NewLoginModal_input__X2Dpi::placeholder {
  color: var(--sk-color-grey-600);
  text-transform: none;
}

.NewLoginModal_input__selected__StaAu {
  background: var(--sk-space-16) center / 30px no-repeat;
  text-indent: 40px;
}

.NewLoginModal_input__X2Dpi:hover,
.NewLoginModal_input__X2Dpi:focus {
  border-color: var(--sk-color-black);
  outline: 2px solid var(--sk-color-black);
  outline-offset: -1px;
}

.NewLoginModal_iconDelete__Oea_3 {
  position: absolute;
  right: var(--sk-space-12);
  cursor: pointer;
}

.NewLoginModal_iconDelete__Oea_3:hover {
  background-color: transparent; /* avoid an unsightly hover effect */
}

.NewLoginModal_autocompleteList__6mL6m {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  max-height: 25vmin;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-bottom: none;
  border-radius: var(--sk-radius-8);
  list-style: none;
}

.NewLoginModal_autocompleteItem__RSOEr {
  padding: var(--sk-space-12);
  border-bottom: 1px solid #d4d4d4;
  background-color: var(--sk-color-white);
  font-size: var(--sk-font-size-2);
  cursor: pointer;
}

.NewLoginModal_alternativeLogin__E4zES {
  text-align: center;
}

.NewLoginModal_button__P_wPm {
  width: 100%;
  margin-top: auto;
  margin-bottom: var(--sk-space-32);
}

@media (min-width: 768px) {
  .NewLoginModal_head__9h832 {
    margin-bottom: var(--sk-space-32);
  }

  .NewLoginModal_autocomplete___GBWW {
    margin-bottom: var(--sk-space-8);
  }

  .NewLoginModal_alternativeLogin__E4zES {
    order: 2;
  }

  .NewLoginModal_button__P_wPm {
    order: 1;
  }
}

}
@layer base {
  .Header_uploadFile__tOiGw {
  margin-bottom: var(--spacer-300);
}

}
@layer ui {
  /** @define Textarea */

.textarea_jds-Textarea__3gkpv {
  box-sizing: border-box;
  width: 100%;
  padding: var(--spacer-150) var(--spacer-200);
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-200);
  font-family: var(--fontFamily);
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.textarea_jds-Textarea--noResize__urVcx {
  resize: none;
}

.textarea_jds-Textarea__3gkpv::-moz-placeholder {
  color: var(--color-grey--200);
}

.textarea_jds-Textarea__3gkpv::placeholder {
  color: var(--color-grey--200);
}

.textarea_jds-Textarea__3gkpv:not(:disabled):hover,
.textarea_jds-Textarea__3gkpv:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.textarea_jds-Textarea__3gkpv:disabled {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--025);
  color: var(--color-grey--200);
}

.textarea_jds-Textarea__3gkpv:disabled::-moz-placeholder {
  color: var(--color-grey--200);
}

.textarea_jds-Textarea__3gkpv:disabled::placeholder {
  color: var(--color-grey--200);
}

.textarea_jds-Textarea--hasError__HlHV9 {
  border-color: var(--color-error--500);
}

.textarea_jds-Textarea--hasError__HlHV9:not(:disabled):hover,
.textarea_jds-Textarea--hasError__HlHV9:not(:disabled):focus {
  outline-color: var(--color-error--500);
}

.textarea_jds-Textarea__switchWrapper__PIKC3 {
  display: flex;
}

.textarea_jds-Textarea__counter__d_KwE {
  flex-shrink: 0;
  margin-left: auto;
  padding-top: var(--spacer-50);
  padding-left: var(--spacer-200);
  color: var(--color-grey--600);
}

.textarea_jds-Textarea__counter--error__TZ6bi {
  color: var(--color-error--600);
}

}
@layer base {
  .Services_serviceField__lKVUl {
  flex: 1 0 auto;
}

.Services_shortButtons__PbfPo {
  margin-bottom: var(--sk-space-16);
}

}
@layer base {
  .Teams_uploadFile__5aPQt {
  margin-bottom: var(--spacer-300);
}

.Teams_member__dSkxe {
  margin-top: var(--sk-space-32);
  padding-top: var(--sk-space-32);
  border-top: 1px solid var(--sk-color-grey-100);
}

.Teams_memberList__P_6Rv {
  padding: 0;
  list-style-type: none;
}

}
@layer base {
  /* stylelint-disable max-line-length */

/**
 * UI-Kit uses react-select and this one uses the Emotion CSS-in-JS library to style its components.
 * CSS-in-JS libraries are not compatible with our @layer strategy of CSS loading.
 * That's why we have to use !important on nextjs context.
 */

.Select_select__qM0Ez [class*="jds-Select__control"] {
  border-radius: var(--radius-200) !important;
  cursor: pointer !important;
}

.Select_select__qM0Ez [class*="jds-Select__dropdown-indicator"] {
  padding-right: var(--spacer-200) !important;
  padding-left: calc(var(--spacer-150) / 2) !important;
  color: var(--color-black) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option"] {
  padding: var(--spacer-150) var(--spacer-200) !important;
}

.Select_select__qM0Ez [class*="jds-GroupedCheckbox"] [class*="jds-Select__option"] {
  padding-left: calc(var(--spacer-200) * 2) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option--is-selected"] {
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option--is-selected"] [class*="jds-Option__TickBox"] {
  color: var(--color-white) !important;
}

.Select_select__qM0Ez [class*="jds-Select__option--is-focused"],
.Select_select__qM0Ez [class*="jds-Select__option:hover"],
.Select_select__qM0Ez [class*="jds-Select__option:focus"] {
  background-color: var(--color-grey--025) !important;
}

.Select_select__qM0Ez[class*="jds-Select--hasError"] .jds-Select__control {
  border-color: var(--color-error--500) !important;
}

.Select_select__qM0Ez [class*="jds-Select__input-container"] {
  margin: 0 !important;
}

.Select_select__qM0Ez [class*="jds-Select__multi-value"] {
  border-radius: var(--radius-200) !important;
  cursor: pointer !important;
}

.Select_select__qM0Ez [class*="jds-Select__placeholder"],
.Select_select__qM0Ez [class*="jds-Select__single-value"],
.Select_select__qM0Ez [class*="jds-Select__input"] {
  margin: 0 !important;
}

.Select_select__qM0Ez
  [class*="jds-Select__control--is-focused jds-Select__control--menu-is-open"]
  [class*="jds-Select__single-value"] {
  color: var(--color-grey--500) !important;
}

.Select_select__qM0Ez [class*="jds-Select__value-container"] {
  padding: 0 0 0 var(--spacer-200) !important;
}

.Select_select__qM0Ez [class*="jds-Select__value-container--is-multi"] {
  max-height: 82px !important;
  overflow: auto !important;
}

.Select_select__qM0Ez [class*="jds-Select__value-container--is-multi jds-Select__value-container--has-value"] {
  padding: var(--spacer-100) 0 var(--spacer-100) var(--spacer-200) !important;
}

.Select_select__qM0Ez [class*="jds-Select__multi-value__remove"] {
  background-color: inherit !important;
  color: inherit !important;
  cursor: pointer !important;
}

.Select_select__qM0Ez .jds-Select__menu {
  box-shadow: var(--default-shadow) !important;
}

.Select_select__qM0Ez [class*="jds-Select__menu-list"] {
  max-height: 264px !important;
  padding: 0 !important;
  border-bottom: 1px solid var(--color-grey--100) !important;
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__menu {
  border-radius: var(--radius-400) !important;
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__indicators {
  height: var(--Select-innerHeight);
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez [class*="IndicatorContainer_jds-IndicatorContainer__tag"] {
  margin-right: var(--spacer-200);
  padding: 0 var(--spacer-50) 0 var(--spacer-100);
  background-color: var(--color-grey--100);
  color: var(--color-black);
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__multi-value {
  border-radius: var(--radius-200) !important;
  background-color: var(--color-black) !important;
  color: var(--color-white) !important;
}

[data-theme="jobteaser-uishift"] .Select_select__qM0Ez .jds-Select__multi-value__label {
  color: var(--color-white) !important;
}

@media (min-width: 48rem) {
  [data-theme="jobteaser-uishift"]
    .Select_select__qM0Ez
    [class*="IndicatorContainer_jds-IndicatorContainer__tag"]
    > div[role="button"] {
    display: none;
  }

  [data-theme="jobteaser-uishift"] .Select_select__qM0Ez [class*="IndicatorContainer_jds-IndicatorContainer__tag"] {
    padding: 0 var(--spacer-100) 0 var(--spacer-100);
  }

  [data-theme="jobteaser-uishift"] .Select_select__qM0Ez [class*="IndicatorContainer_jds-IndicatorContainer__tag"]:hover {
    padding: 0 var(--spacer-50) 0 var(--spacer-150);
  }

  [data-theme="jobteaser-uishift"]
    .Select_select__qM0Ez
    [class*="IndicatorContainer_jds-IndicatorContainer__tag"]:hover
    > div[role="button"] {
    display: inherit;
  }
}

}
@layer base {
  .Contact_subGroupFieldset__tHKgD {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 0 var(--spacer-100);
}

.Contact_footer__8K4NF {
  margin-top: var(--spacer-300);
}

.Contact_uniFooter__K_D7e {
  margin-bottom: var(--spacer-300);
}

.Contact_footer__8K4NF p {
  margin: 0;
}

.Contact_footer__8K4NF a,
.Contact_link__0ztih {
  color: var(--color-black);
}

.Contact_button__zJOv6 {
  z-index: auto;
}

.Contact_conditions__xg4cP {
  margin-top: var(--spacer-300);
  margin-bottom: var(--spacer-300);
}

@media (min-width: 80rem) {
  .Contact_groupFieldset__HeqJM {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 var(--spacer-200);
  }
}

/* DIAL CODE OVERRIDE - BEGIN */
/* Hide the icons to use the maximum of space */
.Contact_subGroupFieldset__tHKgD [for="dial_code"] + div .jds-Select__clear-indicator,
.Contact_subGroupFieldset__tHKgD [for="dial_code"] + div .jds-Select__option svg {
  display: none !important;
}

@media (min-width: 80rem) {
  /* Makes the value smaller */
  .Contact_subGroupFieldset__tHKgD [for="dial_code"] + div .jds-Select__single-value {
    font-size: var(--smallBody) !important;
  }

  /* Reduce space to maximize the display of the value */
  .Contact_subGroupFieldset__tHKgD [for="dial_code"] + div .jds-Select__value-container {
    padding-right: 0 !important;
  }

  .Contact_subGroupFieldset__tHKgD [for="dial_code"] + div .jds-Select__indicator {
    padding-left: 0 !important;
  }
}
/* DIAL CODE OVERRIDE - END */

}
@layer base {
  .Form_data__ARwMX {
  margin-top: var(--spacer-500);
}

.Form_dataList__DF71Y {
  margin: var(--spacer-500) 0 0;
  padding: 0;
  list-style: none;
}

.Form_dataItem__ieIlb {
  display: flex;
  align-items: center;
  font-size: var(--normalBody);
  font-weight: var(--bold);
  line-height: var(--normalBodyLineHeight);
}

.Form_dataItem__ieIlb:not(:first-child) {
  margin-top: var(--spacer-400);
}

.Form_dataImage__dvMIS {
  width: 56px;
  height: 56px;
  margin-right: var(--spacer-300);
  border-radius: var(--radius-200);
}

.Form_form__Bzqoe {
  position: relative;
  margin: var(--spacer-400) 0;
  padding: var(--spacer-300);
  border: solid 1px transparent; /* !important */
  border-radius: var(--radius-200);
  background-clip: padding-box; /* !important */
  background-color: var(--color-white);
}

.Form_form__Bzqoe::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  inset: 0;
  margin: -1px; /* !important */
  border-radius: inherit; /* !important */
  background: linear-gradient(-15deg, rgb(157 44 255 / 100%) 11%, rgb(0 210 0 / 100%) 100%);
}

@media (min-width: 64rem) {
  .Form_layout__YpUIu {
    position: relative;
  }

  .Form_layout__YpUIu::after {
    content: "";
    position: absolute;
    z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: 535px;
    left: 47%;
    width: 93px;
    height: 93px;
    background-image: var(--triangleFront);
    background-size: cover;
  }

  .Form_layout__YpUIu::before {
    content: "";
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
    top: 580px;
    left: 38%;
    width: 295px;
    height: 123px;
    background-image: var(--triangleBack);
    background-size: cover;
  }

  .Form_form__Bzqoe,
  .Form_data__ARwMX {
    margin: var(--spacer-1000) 0;
  }

  .Form_form__Bzqoe {
    padding: var(--spacer-500);
  }

  .Form_title__rEP8g {
    font-size: var(--headingBig);
    line-height: var(--headingBigLineHeight);
  }

  .Form_dataItem__ieIlb {
    max-width: 75%;
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .Form_dataImage__dvMIS {
    width: 72px;
    height: 72px;
  }
}

}
@layer base {
  .LanguageModal_main__DNIhx {
  overflow-y: visible;
}

.LanguageModal_selectField__Dvx_x {
  /* Use z-index to display the select options list in front of the modal buttons */
  z-index: var(--z-above-ui-kit-buttons);
}

}
@layer base {
  .LegalModal_links__ruSJk {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 10px;
  /* Override BO londinium CSS */
  padding-left: 0;
  list-style-type: none;
  gap: var(--spacer-100);
  font-size: var(--smallBody);
}

}
@layer base {
  .MenuItem_link__HSrUN {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 var(--spacer-100);
  border-radius: var(--radius-100);
  font-size: var(--normalBody);
  text-decoration: none;
  white-space: nowrap;
  gap: var(--spacer-100);
}

.MenuItem_link___unselected__R_QQs {
  color: var(--color-black);
}

.MenuItem_link___selected__wZrm8 {
  background-color: var(--color-black);
  color: var(--color-white) !important;
}

.MenuItem_link___collapsed__WpqKt {
  justify-content: center;
}

.MenuItem_link__HSrUN:hover {
  background-color: var(--color-grey--100);
  text-decoration: none;
}

.MenuItem_link___selected__wZrm8:hover {
  background-color: var(--color-grey--800);
}

.MenuItem_link__HSrUN:focus {
  box-sizing: border-box;
  padding: 0 calc(var(--spacer-100) - 1px);
  border: thin solid var(--color-black);
  outline: none;
}

.MenuItem_linkText__mGy1M {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MenuItem_linkText___hidden__Ctmip {
  display: none;
}

}
@layer base {
  /* stylelint-disable plugin/selector-bem-pattern */
/** @define Loading */

.Loading_sha-Loading__tmLTe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.Loading_sha-Loading__spinner--isOnWhiteBackground__pHKQ_ {
  stop-color: var(--mainColor);
}

}
@layer base {
  .Drawer_main__kqud4 {
  display: flex;
  position: fixed;
  z-index: var(--z-index-website-menu);
  box-sizing: border-box;
  flex-direction: column;
  width: var(--jt-bo-drawer-width);
  /* using svh instead of vh (when available) to avoid the drawer being too long on mobile devices */
  height: calc(100vh - var(--notification-banner-height));
  height: calc(100svh - var(--notification-banner-height));
  padding: 0 var(--spacer-100);
  background-color: var(--color-grey--025);
  box-shadow: 0 0 4px var(--color-grey--100);
}

.Drawer_main__collapsed__xsTvP {
  position: sticky;
  top: var(--notification-banner-height);
  width: var(--jt-bo-drawer-collapsed-width);
}

.Drawer_main__loading__u08fi {
  margin: 0;
}

.Drawer_backdrop__sokMw {
  position: fixed;
  /*
    Below the drawer but above the rest of the page.
    (It is below the drawer with the same z-index because it is before in order of appearance in the HTML.)
  */
  z-index: var(--z-index-website-menu);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: var(--color-black);
}

.Drawer_spacer__Kke79 {
  width: var(--jt-bo-drawer-collapsed-width);
}

.Drawer_header__zltHJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacer-400);
  padding: var(--spacer-200) var(--spacer-150);
  transition: none;
  border-bottom: thin solid var(--color-grey--100);
  text-decoration: none;
  gap: var(--spacer-150);
}

.Drawer_headerLink__6VkMR,
.Drawer_headerLink__6VkMR:hover {
  display: flex;
  gap: var(--spacer-150);
  align-items: center;
  text-decoration: none;
}

.Drawer_header__collapsed__drQvf {
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
}

.Drawer_header__zltHJ:hover {
  text-decoration: none;
}

.Drawer_title__EYavJ {
  flex: 1;
}

.Drawer_collapse__lzPod {
  padding: 0 !important;
  border: none;
  stroke: var(--color-grey--500);
}

.Drawer_collapse__lzPod:hover::before {
  background: none;
}

.Drawer_logo__DLciR {
  /* ensure the link keeps the same size as the image */
  display: block;
  width: 40px;
  height: 40px;
  border-radius: var(--radius-100);
  background-color: var(--color--grey-50);
}

.Drawer_menu__ta8GM {
  display: flex;
  flex-direction: column;
  min-height: 0; /* it allows the flex container to shrink below the height of its content if necessary */
  border-bottom: thin solid var(--color-grey--100);
}

.Drawer_menuItemsList__lyYP6 {
  margin-top: 0;
  /* Override BO londinium CSS */
  margin-bottom: 0;
  /* Override BO londinium CSS */
  padding-left: 0;
  /*
  The scrollable area is only on the menu items list and not on the whole menu,
  because the overflow-y auto or scroll is not compatible with the presence of dropdowns.
  It would create horizontal scrolling that we can't hide without making the dropdowns unusable.
  In CSS, if you are using visible for either overflow-x or overflow-y and something other than visible for the other,
  the visible value is interpreted as auto.
   */
  overflow-y: auto;
  list-style-type: none;
}

.Drawer_dropdowns__D9ciY {
  flex: 1;
}

.Drawer_helpCenter__cBTML {
  margin-top: var(--spacer-150);
  margin-bottom: var(--spacer-200);
}

@media (min-width: 48rem) {
  .Drawer_main__kqud4 {
    position: sticky;
    top: var(--notification-banner-height);
  }
}

}
@layer base {
  .ApprovalPage_loader__K8ty_ {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.ApprovalPage_wrapper__oOn0Z {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: var(--sk-space-24);
}

.ApprovalPage_form__ZGJxt {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.ApprovalPage_block__3GtfD {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.ApprovalPage_link__oKiiq {
  font-size: var(--sk-font-size-1);
}

.ApprovalPage_checkbox__pK7Bw {
  margin-bottom: var(--sk-space-64);
}

.ApprovalPage_ctaBlock__SAFpI {
  position: absolute;
  right: var(--sk-space-24);
  bottom: var(--sk-space-24);
  left: var(--sk-space-24);
  text-align: right;
}

.ApprovalPage_button__B80CO {
  width: 100%;
}

@media (min-width: 48rem) {
  .ApprovalPage_form__ZGJxt {
    align-items: center;
    justify-content: center;
    width: 556px;
    margin: 0 auto;
    gap: var(--sk-space-64);
  }

  .ApprovalPage_block__3GtfD {
    padding: var(--sk-space-32);
    border: 2px solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-16);
  }

  .ApprovalPage_checkbox__pK7Bw {
    margin-bottom: 0;
  }

  .ApprovalPage_ctaBlock__SAFpI {
    position: static;
  }

  .ApprovalPage_button__B80CO {
    width: auto;
  }
}

}
@layer base {
  .BoLayout_layoutWrapper__myDtE {
  --jt-bo-drawer-width: 240px;
  --jt-bo-drawer-collapsed-width: 55px;
}

.BoLayout_pageContentWrapper__VT_Hp {
  display: flex;
}

.BoLayout_mainContent__wWXRf {
  flex: 1;
  container-type: inline-size;
  container-name: bo-layout-main-container;
}

.BoLayout_mainContent__white__A7YT7 {
  background-color: var(--color-white);
}

.BoLayout_mainContent__grey__F3gcC {
  background-color: var(--color-grey--025);
}

}
@layer ui {
  /** @define BreadcrumbTrail */

.BreadcrumbTrail_jds-BreadcrumbTrail__list__aur5V {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.BreadcrumbTrail_jds-BreadcrumbTrail__item__AvmCG {
  display: inline;
  line-height: var(--normalBodyLineHeight);
}

.BreadcrumbTrail_jds-BreadcrumbTrail__item__AvmCG:not(:first-child) {
  margin-left: var(--spacer-100);
}

.BreadcrumbTrail_jds-BreadcrumbTrail__item__AvmCG:not(:first-child)::before {
  content: "/";
  padding-right: var(--spacer-100);
}

.BreadcrumbTrail_jds-BreadcrumbTrail__link__juNAM {
  font-weight: var(--semiBold);
}

}
@layer base {
  .BoTemplateHeader_main__pwucI {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacer-300);
  margin-bottom: var(--spacer-500);
}

.BoTemplateHeader_navigation__M_lL8 {
  display: flex;
  align-items: center;
  gap: var(--spacer-150);
}

.BoTemplateHeader_breadcrumb__ZrPbP {
  display: none;
}

.BoTemplateHeader_titleContainer__1dB3C {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacer-50) var(--spacer-100);
}

@media (min-width: 48rem) {
  .BoTemplateHeader_breadcrumb__ZrPbP {
    display: block;
  }

  .BoTemplateHeader_navigation__M_lL8 {
    gap: var(--spacer-150) var(--spacer-300);
  }
}

}
@layer base {
  .Intro_grid__k0u2f {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacer-400);
}

.Intro_stepsList__4UMgI {
  margin: 0;
  padding: 0;
}

.Intro_stepIcon__QLvL5 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  padding: var(--spacer-200);
}

.Intro_stepItem__D4eVt {
  display: flex;
  align-items: center;
  list-style: none;
}

.Intro_imageColumn__GFbT9 {
  display: none;
}

.Intro_buttons__A4IQY {
  display: flex;
  gap: var(--spacer-200);
  flex-wrap: wrap;
  margin-top: var(--spacer-400);
}

.Intro_mobileShowcaseImage__aS9UW {
  margin: var(--spacer-200) 0;
}

.Intro_showcaseImage__H2mKg {
  width: 100%;
  height: auto;
}

/* large breakpoint */

@container bo-layout-main-container (width >= 1024px) {
  .Intro_grid__k0u2f {
    grid-template-columns: 1fr 1fr;
  }

  .Intro_imageColumn__GFbT9 {
    display: block;
  }

  .Intro_subtitle__8Rt8P {
    margin-bottom: var(--spacer-400);
  }

  .Intro_mobileShowcaseImage__aS9UW {
    display: none;
  }
}

}
@layer base {
  .Details_layoutColumn__5K5WH {
  margin-top: 0;
  margin-bottom: 0;
}

.Details_headerLayout__0j_43 {
  background-color: var(--color-white);
}

}
@layer ui {
  /** @define ElementGrid */

.elementGrid_jds-ElementGrid__1R7_G {
  --ElementGrid-columnsCount: 1;

  display: grid;
  gap: var(--Layout-column-gutter-size);
  grid-template-columns: repeat(var(--ElementGrid-columnsCount), minmax(0, 1fr));
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 48rem) {
  .elementGrid_jds-ElementGrid__1R7_G {
    --ElementGrid-columnsCount: 2;
  }
}

@media (min-width: 64rem) {
  .elementGrid_jds-ElementGrid__1R7_G {
    --ElementGrid-columnsCount: 3;
  }

  .elementGrid_jds-ElementGrid--2Cols__doh_u {
    --ElementGrid-columnsCount: 2;
  }

  .elementGrid_jds-ElementGrid--4Cols__RREmA {
    --ElementGrid-columnsCount: 2;
  }
}

@media (min-width: 80rem) {
  .elementGrid_jds-ElementGrid--4Cols__RREmA {
    --ElementGrid-columnsCount: 4;
  }
}

}
