@layer base {
  .main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.head {
  margin-bottom: var(--sk-space-48);
}

.autocomplete {
  display: block;
  position: relative;
  margin-bottom: var(--sk-space-16);
}

.label {
  margin-bottom: var(--sk-space-8);
}

.input {
  box-sizing: border-box;
  width: 100%;
  min-height: 48px;
  margin-bottom: var(--sk-space-4);
  padding: 0 var(--sk-space-16);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  font-family: inherit;
  font-size: var(--sk-font-size-1);
  font-weight: var(--sk-font-weights-poppins-2);
  appearance: none;
  text-transform: capitalize;
}

.input::placeholder {
  color: var(--sk-color-grey-600);
  text-transform: none;
}

.input__selected {
  background: var(--sk-space-16) center / 30px no-repeat;
  text-indent: 40px;
}

.input:hover,
.input:focus {
  border-color: var(--sk-color-black);
  outline: 2px solid var(--sk-color-black);
  outline-offset: -1px;
}

.iconDelete {
  position: absolute;
  right: var(--sk-space-12);
  cursor: pointer;
}

.iconDelete:hover {
  background-color: transparent; /* avoid an unsightly hover effect */
}

.autocompleteList {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  max-height: 25vmin;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  border: 1px solid #d4d4d4;
  border-top: none;
  border-bottom: none;
  border-radius: var(--sk-radius-8);
  list-style: none;
}

.autocompleteItem {
  padding: var(--sk-space-12);
  border-bottom: 1px solid #d4d4d4;
  background-color: var(--sk-color-white);
  font-size: var(--sk-font-size-2);
  cursor: pointer;
}

.alternativeLogin {
  text-align: center;
}

.button {
  width: 100%;
  margin-top: auto;
  margin-bottom: var(--sk-space-32);
}

@media (--sk-medium-viewport) {
  .head {
    margin-bottom: var(--sk-space-32);
  }

  .autocomplete {
    margin-bottom: var(--sk-space-8);
  }

  .alternativeLogin {
    order: 2;
  }

  .button {
    order: 1;
  }
}

}