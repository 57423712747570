@layer base {
  .uploadFile {
  margin-bottom: var(--spacer-300);
}

.member {
  margin-top: var(--sk-space-32);
  padding-top: var(--sk-space-32);
  border-top: 1px solid var(--sk-color-grey-100);
}

.memberList {
  padding: 0;
  list-style-type: none;
}

}